<template>
    <div class="chooseContainer">
        <div class="chooseProduct">
            <div id="chooseSigner" class="chooseType" @click="qSignDocument()">
                <img src="../../public/qsignLogo.svg" class="imgSign">
                <h3 class="chooseTextAnonyma">Qsign</h3>
                <div class="chooseDescription">Qsign</div>
            </div>
            <div id="chooseAnonyma" class="chooseType" @click="minexSignDocument()">
                <img src="../../public/minexLogo.svg" class="imgDocuments">
                <h3 class="chooseTextAnonyma">Minex</h3>
                <div class="chooseDescription">Minex</div>
            </div>
            <div id="chooseImageSignature" class="chooseType" @click="imageSignature()">
                <img src="../../public/minexLogo.svg" class="imgDocuments">
                <h3 class="chooseTextImageSignature">Image Signature</h3>
                <div class="chooseDescription">Image Signature</div>
            </div>
        </div>
    </div>
    <!-- document signing -->
    <teleport to="body">   
        <div class="modalExport" v-show="showSigner"> 
            <div class="modalSignerViewExport">
                <div class="highlightHeaderContent">
                    <h3 class="highlightTitle"> {{ this.filename }} </h3>
                    <div id="closeHighlight" @click="showSigner = !showSigner"> X </div>
                </div>
                <div class="signerIframe">
                    <iframe class="signer" v-if="signerLink" :src="signerLink" ></iframe>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import {qSign, minexSign, qSignMultiple} from "../services";
import { mutationMethods } from "../store/Istore.js";
export default {
    name: "SingleSign",
    data () {
        return {
            showSigner: false,
            signerLink: '',
            filename: '',
        }
    },
    methods: {
        prelink() {

        },
        imageSignature(){
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.multiple = true;
            fileInput.accept = 'application/pdf'; 

            fileInput.click();

            fileInput.onchange = async (event) => {
                this.signerLink = ''
                this.filename = ''

                const files = event.target.files;
                if (files.length > 0) {
                    const file = files[0];
                    this.$store.commit(mutationMethods.ADD_IMAGE_SIGNATURE_FILE, file);

                    // Navigate to ImageSignature route
                    this.$router.push({ name: 'ImageSignature' });
                    
                    }
            }
        },
        qSignDocument(){
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.multiple = true;
            fileInput.accept = 'application/pdf'; 

            fileInput.click();

            fileInput.onchange = async (event) => {
                this.signerLink = ''
                this.filename = ''

                const files = event.target.files;

                if (files.length == 1) {
                    console.log('sending one file to the server')

                    const file = files[0]
                    const formData = new FormData();
                    this.filename = file.name;
                    console.log(this.filename);
                    formData.append('file', file, this.filename);
                    formData.append('lang', this.$i18n.locale);

                    const response = await qSign(formData);

                    if (response.status != 200) {
                        console.error(response);
                        return;
                    }
                    console.log(response.data.redirect_url);

                    this.signerLink = response.data.redirect_url;
                    this.showSigner = !this.showSigner
                } else {
                    console.log('sending multiple files to the server')

                    const formData = new FormData();
                    Array.from(files).forEach((file) => {
                        formData.append(file.name, file);
                    });
                    formData.append('lang', this.$i18n.locale);
                    
                    const response = await qSignMultiple(formData);
                    if (response.status != 200) {
                        console.error(response);
                        return;
                    }
                    console.log(response.data.redirect_url);

                    this.signerLink = response.data.redirect_url;
                    this.showSigner = !this.showSigner
                }
            };
        },

        minexSignDocument(){
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'application/pdf'; 

            fileInput.click();

            fileInput.onchange = async (event) => {
                this.signerLink = ''
                this.filename = ''

                const file = event.target.files[0];
                const formData = new FormData();
                this.filename = file.name;
                console.log(this.filename);
                formData.append('file', file, this.filename);
                formData.append('lang', this.$i18n.locale);

                const response = await minexSign(formData);

                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(pdfBlob);
                downloadLink.download = this.filename;
                downloadLink.click();

        };
    }
    }
}
</script>


<style scoped>

.chooseContainer 
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
}

.chooseProduct {
    display: inline-flex;
    /* justify-content: space-evenly; */
     /* padding-top: 2%; */
    color: #105EA9;
    column-gap: 2rem;
    row-gap: 2rem;
    margin-top: 2rem;
    justify-content: center;
    flex-wrap: wrap;
}

.chooseType:hover 
{
    cursor: pointer;
    box-shadow: rgba(57, 57, 57, 0.2) 0px 2px 8px 0px;
}


.chooseProduct:last-child 
{
    
    margin-bottom: 2rem;
}

.chooseProduct img 
{
    height: 90px;
}

.chooseIcons {
    text-align: center;
    padding: 15px;
    border-radius: 15px;

}

.chooseDescription {
    max-width: 300px;
    color: #9d9d9d;
    margin-inline: 1rem;            
    font-size: 14px;
}

.chooseSearch {
    background-color: #2156A4;
    color: white;
}

.chooseAnonyma {
    background-color: white;
    color: #2156A4;
}

.chooseType {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;;
    width: 18rem;
    background: #E9E9E9;
    align-items: center;
    justify-content: flex-end;
    border-radius: 28px;
}

.chooseText {
    font-size: 26px;
    margin: 15px;
}
.chooseTextAnonyma {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-inline: 5rem;
    margin-block: 2rem;
}

.chooseTextImageSignature{
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-block: 2rem;
}


#chooseSearch:hover, #chooseAnonyma:hover, #chooseSigner:hover{
    color: #4a87c0;
}
.chooseSearch {
    background-color: #4a87c0;
}

/* highlighting neeed to be splitted and change names */
.modalExport {
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalExport > div {
    overflow: hidden;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
}

.modalSignerViewExport {
    width: 70%;
    height: 95%;
    font-family: 'Poppins', sans-serif;;
    overflow-y: auto;
}

.highlightHeaderContent {
    background-color: lightgrey;
    margin: 5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.highlightTitle {
    width: 97%;
    text-align: center;
}

#closeHighlight {
    text-align: center;
    width: 3%;
    height: 3%;
    background-color: darkgray;
    border-radius: 5px;
    margin-right: 1%;
}

.signerIframe {
    height: 90%;
    margin: 5px;
}

.signer {
    width: 100%;
    height: 100%;
    border: none;
}


</style>