<template>
	<div id="main">
		<div id="sidebar">
			
			<div style="height: 100%;">
				<div class="hedder">
					<div style="flex-wrap: nowrap;">
						<input type="file" ref="fileInput" accept=".png, .jpg, .jpeg" @change="handleFileChange" />
						<button class="btn" @click="addOverlay">Add Overlay</button>
					</div>
					<button class="btn" @click="handlePost">Export</button>
				</div>
				<div v-for="(overlay, index) in this.overlays" :key="index" class="middle">
					<div class="overlayBox" @click="jumpOnPage(overlay.page_number, index)" :class="{ 'highlighted': currentEdit === index }"> 
						<div class="overlayBoxImg" :style="{
							backgroundImage: 'url(' + overlay.low_res_img + ')',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							height: '80px',
							width: '120px'
						}">
							
							
						</div>
						<div class="labels">
							<h4>{{overlay.name}}
							</h4>
							<span>Strana: {{ overlay.page_number }}</span>
						</div>
						<div>

							<div v-if="this.currentEdit === index">
								<img src="../../public/delete.png" class="delete-comment"  @click.stop="delete_image(index)">
							</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="flex-down">
				<span @click="handlePrevious">◀</span>
				<input ref="page_number_input" id="pageNumber-input" type="number" @input="handlePageChange" :value="page_number"/>
				<p>/ {{ numberOfPages }} </p>
				
				<span @click="handleNext" >▶</span>
			
			</div>
			
		</div>
		<div id="content">
			<!-- PDF viewer -->
			<div @mousemove="handleDragging" @mousedown="handleDraggingStop">
				<canvas ref="canvas" id="theCanvas" @wheel="handleMouseWheel" @click="handleCanvasClick" @mousemove="handleMouseMove"
					@mouseup="handleMouseUp(false)" @mousedown="handleMouseDown"></canvas>
			</div>
			<!-- image overlay of dragging image  -->
			<div id="imgOverlay" ref="imgOverlay"></div>
			<!-- red overlay over the current dragging image  -->
			<div id="redHighLight" ref="redHighLight"></div>
		</div>

		<!-- for loop for showing overlays over pdf view -->
		<div class="overlays" ref="overlays" v-for="(overlay, index) in this.overlays" :key="index">
			<div class="zoom-controls" v-if="index === this.currentEdit"
				:style="{
					left: overlay.left,
					top: `calc(${overlay.top} - 30px)`,
					position: 'absolute',
					display: 'flex',
					gap: '5px'
					}">
				<button @click.stop="handleScaling(index, 0.75)">-</button>
				<button @click.stop="handleScaling(index, 1.25)">+</button>
			</div>
			<div :ref="overlay.ID" v-if="overlay.page_number === this.page_number" @mousedown="handleDragStart(index, $event)"
				:style="{
					left: overlay.left,
					top: overlay.top,
					width: overlay.width,
					height: overlay.height,
					position: 'absolute',
					backgroundImage: 'url(' + overlay.low_res_img + ')',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					opacity: '0.7',
					pointerEvents: overlay.pointerEvents,
					border: overlay.border_color,
					
				}">
			</div>
		</div>

		
	</div>
</template>


<script>
import * as pdfjsLib from "pdfjs-dist";
import { getterMethods } from "../store/Istore.js";
import { imageSignature } from "@/services.js";

export default {
	name: "ImageSignature",
	components: {},
	data() {
		return {
			previousXY : [],
			low_res_img: NaN,
			overlays: [],
			isDragging: false,
			overlay: false,
			left: 0,
			top: 0,
			width: 0,
			height: 0,
			currentImage: NaN,
			imageFiles: [],
			currentEdit: NaN,
			dragging: false,
			indexDraggedImg: NaN,
			page_number: 1,
			numberOfPages: NaN,
			aspectRatio: [],
			realPoint: NaN,
			pdfDocument: null,
			pageRendering: false,
			pageNumPending: null,
			fileName: '',
		};
	},
	methods: {
		jumpOnPage(page_number, index){
			this.page_number = page_number;
			this.render_page();
			this.handleEdit(index);
		},
		handleMouseWheel(event) {
			// Prevent the default scroll behavior
			event.preventDefault();
			
			// Get the scrollable element (e.g., the container with overflow)
			const scrollableElement = event.currentTarget;

			// Get the scroll position and the scrollable height
			const { scrollTop, scrollHeight, clientHeight } = scrollableElement;

			console.log(scrollTop, scrollHeight, clientHeight)
			
			// Check if the user is at the top or the bottom of the content
			const atTop = scrollTop === 0;
			console.log(scrollTop)
			const atBottom = scrollTop + scrollHeight >= scrollHeight;
			console.log(atBottom)
			if (event.deltaY < 0 && atTop) {
				// Scroll up at the top of the content
				console.log("HORE")
				this.handlePrevious();
			} else if (event.deltaY > 0 && atBottom) {
				console.log("DOLE")
				// Scroll down at the bottom of the content
				this.handleNext();
			}
		},
		async load_file(){
			
			// Setting worker path to worker bundle.
			pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

			// Loading a document.
			const fileData = await this.readFileAsArrayBuffer(this.file);

			const loadingTask = pdfjsLib.getDocument(fileData);
			const result = await loadingTask.promise;
			
			// get number of pages
			this.numberOfPages = result.numPages;
			return result;
		},
		async render_page() {
			if (this.pageRendering) { // Check if other page is rendering
				this.pageNumPending = this.page_number;
			} else {
				this.pageRendering = true;
				// Request a first page
				const pdfPage = await this.pdfDocument.getPage(this.page_number)
					// Display page on the existing canvas with 100% scale.

				const viewport = pdfPage.getViewport(1.0);
				const canvas = document.getElementById("theCanvas");
				canvas.width = viewport.width;
				canvas.height = viewport.height;
				const ctx = canvas.getContext("2d");
				
				// Render PDF content
				const renderTask = pdfPage.render({
					canvasContext: ctx,
					viewport,
				});

				const self = this
				
				renderTask.promise.then(function () {
					self.pageRendering = false;
					if (self.pageNumPending !== null) {
						// Waited page must be rendered
						self.render_page();
						// Must be set to null to prevent infinite loop
						self.pageNumPending = null;
					}
			});
					
			}	
		},
		async readFileAsArrayBuffer(file) {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsArrayBuffer(file);
            });
        },
		addOverlay() {
			if (this.currentImage.isNaN != true) {
				this.overlay = true
			}
			
		},

		delete_image(index) {
			this.overlays.splice(index, 1);
			this.imageFiles.splice(index, 1);
		},

		handleFileChange(event) {
			// Handle the file change event here
			const selectedFile = event.target.files[0];


			var fileType = selectedFile.type;

			if (fileType != 'image/png' && fileType != 'image/jpeg' && fileType != 'image/jpg') {
				event.target.files[0] = null;
				return;
			}

			// Check if a file is selected
			if (selectedFile) {
				// Read the file as a data URL
				const reader = new FileReader();

				reader.onload = () => {
					// Create an Image element for downsizing
					const img = new Image();
					img.src = reader.result;
					img.onload = () => {
						// Set the original image data URL
						this.currentImage = reader.result;
						this.imageFiles.push(selectedFile);
						this.aspectRatio = img.width / img.height;


						this.fileName = selectedFile.name;
						// Set the new width and height for downscaling
						const newWidth = 250;
						const newHeight = (img.height / img.width) * newWidth;

						// Create a canvas element to draw the downscaled image
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');

						canvas.width = newWidth;
						canvas.height = newHeight;

						// Draw the image on the canvas with the new size
						ctx.drawImage(img, 0, 0, newWidth, newHeight);

						// Save the downscaled image as a data URL
						const downscaledImage = canvas.toDataURL();

						// Add the downscaled image data URL to the low_res_img array
						this.low_res_img = downscaledImage;
					};

					img.onerror = () => {
						// If image loading fails, set currentImage and low_res_img to NaN
						this.currentImage = NaN;
						// this.imageFiles.pop();
						this.low_res_img = NaN;
						event.target.value = null;
						this.fileName = '';
					};
				};

				reader.readAsDataURL(selectedFile);
			}
		},
		handleMouseMove(event) {
			if (this.isDragging && this.overlay && this.currentImage) {
				const canvasRect = this.$refs.canvas.getBoundingClientRect();

				const startX = this.dragStartX;
				const startY = this.dragStartY;

				// Calculate the maximum allowed positions for the bounding box
				const maxX = Math.min(startX, canvasRect.right);
				const maxY = Math.min(startY, canvasRect.bottom);
				const minX = Math.max(0, Math.min(startX, event.clientX));
				const minY = Math.max(0, Math.min(startY, event.clientY));
				const width = Math.min(Math.abs(event.clientX - startX), canvasRect.right - maxX);
				const height = Math.min(Math.abs(event.clientY - startY), canvasRect.bottom - maxY);

				// Update the position and size of the red overlay over current dragging image
				const redHighLight = this.$refs.redHighLight;
				redHighLight.style.position = "absolute";
				redHighLight.style.left = `${minX}px`;
				redHighLight.style.top = `${minY}px`;
				redHighLight.style.width = `${width}px`;
				redHighLight.style.height = `${height}px`;
				redHighLight.style.backgroundColor = 'red';
				redHighLight.style.opacity = "0.2";
				redHighLight.style.pointerEvents = 'none';

				// Update the position and size of the image overlay
				const imgOverlay = this.$refs.imgOverlay;
				imgOverlay.style.position = "absolute";
				imgOverlay.style.left = `${minX}px`;
				imgOverlay.style.top = `${minY}px`;
				imgOverlay.style.width = `${width}px`;
				imgOverlay.style.height = `${height}px`;
				imgOverlay.style.backgroundImage = 'url(' + this.low_res_img + ')';
				imgOverlay.style.backgroundSize = 'contain';
				imgOverlay.style.backgroundRepeat = 'no-repeat';
				imgOverlay.style.backgroundPosition = 'center';
				imgOverlay.style.opacity = "0.3";
				imgOverlay.style.pointerEvents = 'none';

				// Update the position and size of the bounding box overlay
				this.left = `${minX}px`;
				this.top = `${minY}px`;
				this.width = `${width}px`;
				this.height = `${height}px`;
				this.img = this.currentImage;
			}
		},
		handleMouseDown(event) {
			console.log('this.overlay')
			// This method is called when the user clicks on the canvas and starts dragging the bounding box
			if (isNaN(this.currentImage) && this.overlay) {
				this.dragStartX = event.clientX;
				this.dragStartY = event.clientY;
				this.isDragging = true;
			}
		},

		fitInnerShape(outer_width, outer_height, inner_aspectRatio) {
			// Calculate the aspect ratio of the outer shape
			const outer_aspectRatio = outer_width / outer_height;
			if (outer_aspectRatio > inner_aspectRatio) {
				// The outer shape is wider than the inner shape so the inner shape should be centered vertically
				return {
					width: outer_height * inner_aspectRatio,
					height: outer_height
				};
			}
			else {
				// The outer shape is taller than the inner shape so the inner shape should be centered horizontally
				return {
					width: outer_width,
					height: outer_width / inner_aspectRatio
				}
			}
		},

		async calculateRealPosition() {
			// calculate the real position of the overlays image on the pdf because the image representation between web view and pdf view is different
			const realPoint = [];
			let wrong_position = false;

			for (let i = 0; i < this.overlays.length; i++) {
				// Get the overlay data
				const overlay = this.overlays[i];
				const width = parseInt(overlay.width, 10);
				const height = parseInt(overlay.height, 10);

				const shape = this.fitInnerShape(width, height, overlay.aspectRatio);

				//  Calculate the mid point of the bounding box
				const midX = parseInt(overlay.left, 10) + width / 2;
				const midY = parseInt(overlay.top, 10) + height / 2;
				
				// Get the canvas element and its dimensions
				const canvas = this.$refs.canvas;
				const rect = canvas.getBoundingClientRect();
				const top = rect.top;
				const left = rect.left;
				const widthCanvas = rect.width;
				const heightCanvas = rect.height;

				// Calculate the new position of the shape
				let newLeft = midX - shape.width / 2;
				let newTop = midY - shape.height / 2;

				// Check if the point is outside the canvas
				if (newLeft - left < 0) {
					newLeft = 0;
					wrong_position = true
				}

				if (newTop - top < 0) {
					newTop = 0;
					wrong_position = true
				}

				if (newLeft - left + shape.width > widthCanvas) {
					newLeft = widthCanvas - shape.width;
					wrong_position = true;
				}

				if (newTop - top + shape.height > heightCanvas) {
					newTop = heightCanvas - shape.height;
					wrong_position = true;
				}

				// Add the real position to the realPoint array
				realPoint.push({
					'left': newLeft - left,
					'top': newTop - top,
					'width': shape.width,
					'height': shape.height,
					'img': overlay.img,
					'page': overlay.page_number,
				});
			}

			if (wrong_position) {
				console.log('wrong position');
			}

			this.realPoint = realPoint;
		},


		handleMouseUp(flag = false) {
			// This method is called when the user releases the mouse button after dragging the bounding box
			// and adds the overlay to the array with overlay data
			if (this.overlay && this.currentImage) {
				
				// Reset the dragging state
				this.isDragging = false;
				this.overlay = false;

				// Reset the bounding box overlay
				const imgOverlay = this.$refs.imgOverlay;
				imgOverlay.style.opacity = "0";
				const redHighLight = this.$refs.redHighLight;
				redHighLight.style.opacity = "0";

				// return early if flag is true to prevent adding overlay when resizing the window
				console.log("Flag", flag)
				if (flag) {
					return;
				}

				// Add the overlay to the array
				this.overlays.push({
					"left": this.left,
					"top": this.top,
					"width": this.width,
					"height": this.height,
					"low_res_img": this.low_res_img,
					"name": this.fileName,
					'border_color': 'none',
					'pointerEvents': 'none',
					'page_number': this.page_number,
					'aspectRatio': this.aspectRatio,
					ID: this.overlays.length
				});

				
			}
		},

		handleScaling(index, scale) {
			// Get the div element and its dimensions
			const div = this.$refs[index][0];
			let styles = window.getComputedStyle(div);
			let width = parseFloat(styles.width);
			let height = parseFloat(styles.height);
			let left = parseFloat(styles.left);
			let top = parseFloat(styles.top);

			let right = left + width; // Right edge position of the div
			let bottom = top + height; // Bottom edge position of the div

			const canvasRect = this.$refs.canvas.getBoundingClientRect();
			const canvasRight = canvasRect.right;
			const canvasBottom = canvasRect.bottom;

			// Check if the point is outside the canvas
			if (right * scale > canvasRight || bottom * scale > canvasBottom) {
				console.log('Point is outside the canvas');

				// Calculate the required scale to fit within the canvas
				let scaleToFitWidth = canvasRight / right;
				let scaleToFitHeight = canvasBottom / bottom;

				// Choose the smaller scale to ensure it fits within the canvas
				let minScale = Math.min(scaleToFitWidth, scaleToFitHeight);

				// Update rectangle dimensions
				this.overlays[index].width = (width * minScale) + 'px';
				this.overlays[index].height = (height * minScale) + 'px';
			} else {
				// Update rectangle dimensions
				this.overlays[index].width = (width * scale) + 'px';
				this.overlays[index].height = (height * scale) + 'px';
			}
		},

		handleEdit(index) {
			// If the current edit is the same as the index, reset the current edit
			if (index == this.currentEdit) {
				this.currentEdit = NaN;
				this.overlays.forEach((overlay) => {
					overlay.border_color = 'none';
					overlay.pointerEvents = 'none';

				});
			}
			// Otherwise, set the current edit to the index
			else {
				this.currentEdit = index;
				// Reset border_color for all overlays
				this.overlays.forEach((overlay, i) => {
					overlay.border_color = i === index ? '1px solid red' : 'none';
					overlay.pointerEvents = i === index ? 'auto' : 'none';
				});
			}
		},
		handleDragStart(index, event) {
			if (this.currentEdit === index) {
				this.dragging = !this.dragging;
				this.indexDraggedImg = index;

				// Capture initial offsets
				let element = this.$refs[this.indexDraggedImg][0];
				this.initialOffsetX = event.clientX - element.getBoundingClientRect().left;
				this.initialOffsetY = event.clientY - element.getBoundingClientRect().top;

				this.overlays[index].pointerEvents = 'none';
			}
		},
		handleDragging(event) {
			if (this.dragging) {
				// get the div element
				let div = this.$refs[this.indexDraggedImg][0];

				// Calculate new positions based on the initial offsets
				let newLeft = event.clientX - this.initialOffsetX;
				let newTop = event.clientY - this.initialOffsetY;

				// Ensure the image does not go beyond the canvas boundaries
				let maxRight = this.$refs.canvas.getBoundingClientRect().right - div.offsetWidth;
				let maxBottom = this.$refs.canvas.getBoundingClientRect().bottom - div.offsetHeight;

				newLeft = Math.max(0, Math.min(newLeft, maxRight));
				newTop = Math.max(0, Math.min(newTop, maxBottom));

				requestAnimationFrame(() => {
					this.overlays[this.indexDraggedImg].left = `${newLeft}px`;
					this.overlays[this.indexDraggedImg].top = `${newTop}px`;
				});
			}
		},
		handleDraggingStop() {
			// If the image is being dragged, stop dragging
			if (this.dragging && !isNaN(this.indexDraggedImg)) {
				this.dragging = false;
				this.overlays[this.indexDraggedImg].pointerEvents = 'auto';
				this.indexDraggedImg = NaN;
			}
		},

		async handleNext() {
			this.indexDraggedImg = NaN;

			this.currentEdit = NaN;
				this.overlays.forEach((overlay) => {
					overlay.border_color = 'none';
					overlay.pointerEvents = 'none';

				});
			// change the page number to the next page
			try {
				if (this.page_number < this.numberOfPages){
					this.page_number++;
					await this.render_page();
				}
			} catch (error) {
				console.log(error);
			}
		},

		async handlePrevious() {
			this.currentEdit = NaN;
			this.indexDraggedImg = NaN;
			// change the page number to the previous page
			try {
				if (this.page_number > 1) {
					this.page_number--;
					await this.render_page();
				}
			} catch (error) {
				console.log(error);
			}

		},

		async fetchRawPdfData(pdfFile) {
			// This method get raw data of the pdf
			try {
				const dataUrl = await new Promise((resolve) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.readAsDataURL(pdfFile);
				});

				return dataUrl;
			} catch (error) {
				throw new Error(`Error fetching raw PDF data: ${error.message}`);
			}
		},

		async handlePost() {
			var formData = new FormData()
			// This method posts the data to the server in json format
			await this.exportData(formData);
			try {
				const response = await imageSignature(formData);
				console.log(response);

				if (response.status != 200) {
                        console.error(response);
                        return;
                }
				
				const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

				const contentDisposition = response.headers['content-disposition'];
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(contentDisposition);
				
				let filename = 'file.pdf'; 
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, '');
				}

                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(pdfBlob);
                downloadLink.download = filename;
                downloadLink.click();
				
			} catch (error) {
				console.error(error);
			}
		},

		async exportData(formData) {
			// NOTE : realPoint layout is as follows {left, top, width, height, img, page}
			// NOTE : PdfFile is the raw data of the pdf
			try {
				// Calculate real position
				await this.calculateRealPosition();
				
				formData.append('file', this.file);
				formData.append('Overlays', JSON.stringify(this.realPoint));
				console.log("HURRAAAA",this.imageFiles);
				for (var index = 0; index < this.imageFiles.length; index++) {
					formData.append("images", this.imageFiles[index]);
				}

				console.log('exportData', this.imageFiles);
			} catch (error) {
				console.error('Error fetching raw PDF data:', error);
			}
		},
		async handlePageChange() {
			// Convert input value to a number and ensure it's within valid range
			let pageNumber = parseInt(this.$refs.page_number_input.value);
			if (pageNumber){

				if (pageNumber >= 1 && pageNumber <= this.numberOfPages) {
					this.page_number = pageNumber;
					await this.render_page();
				} else {
					console.log("Page number is out of bounds."); // Optional: handle error state
					this.$refs.page_number_input.value = this.page_number; // Reset value to current page if out of bounds
				}
			}
		},
		handleResize() {
			// disable the edit mode when the window is resized
			this.handleEdit(this.currentEdit);
			this.handleDraggingStop()
			// If is adding overlay and resizing the window, remove the overlay and reset the dragging state
			this.handleMouseUp(true)
			// This method is called on resize of the window it appends difference of the previous and current position of the canvas to the overlays and moves them accordingly.
			if (this.overlays.length > 0) {
				this.previousXY.push({ X : this.$refs.canvas.getBoundingClientRect().left , Y : this.$refs.canvas.getBoundingClientRect().top });
				if (this.previousXY.length > 2) {
					// Remove the first element from this.previousXY something like FIFO.
					this.previousXY.shift();

					let dx = this.previousXY[0].X - this.previousXY[1].X;
					let dy = this.previousXY[0].Y - this.previousXY[1].Y;

					this.overlays.forEach((overlay) => {
						// Move the overlays by the difference in the position of the canvas
						overlay.left = parseFloat(overlay.left, 10) - dx + 'px';
						overlay.top = parseFloat(overlay.top, 10) - dy + 'px';
					});
				}
			}
		},
		
	},
	mounted() {
		this.file = this.$store.getters[getterMethods.GET_UPLOADED_FILE_IMAGE_SIGNATURE];

		this.load_file().then(result => {
			this.pdfDocument = result;
			this.render_page();
			window.addEventListener("resize", this.handleResize);
			this.previousXY.push({ X: this.$refs.canvas.getBoundingClientRect().left ,	Y: this.$refs.canvas.getBoundingClientRect().top });
		});
	},
	unmounted() {
		window.removeEventListener("resize", this.handleResize);
	},
};
</script>

<style>
body {
	background-color: rgb(66, 66, 66);
	margin: 0;
	padding: 0;
}

#main {
    display: flex;
    height: 90vh;
    margin-top: -1rem;
}

#sidebar {
    position: fixed; 
    top: 0; 
    right: 0; 
    width: 25%; 
    height: 90vh; 
    overflow-y: auto; 
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
	margin-top: 4rem
}

#content {
    position: absolute;
    top: 0;
    left: 0;
    right: 25%; 
    height: 90vh; 
    display: flex;
    justify-content: center;
	
}

#main {
    display: flex;
    width: 100%; 
    height: 90vh; 
    position: relative;
}

.flex-sideways {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-down {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
	border-bottom: 1px solid #E1E5F0;
	box-shadow: rgba(100, 100, 111, 0.123) 15px 1px 24px 5px;
}
.flex-down input{ 
	display: block;
	width: 30px; 
	margin-right: 5px;
}
.flex-down span{
	font-size: 25px;
	cursor: pointer;
	color: #5c5c5c;
	margin-inline: 5px;
	webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.overlayBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f9fb;
    
    height: 80px;
    border-radius: 5px;
	width: 90%;
	margin-bottom: 10px;
}
.overlayBoxImg{
	border-radius: 10px 0 0 10px;
}
.highlighted {
    border: 1px solid #2156a4; /* A gold border, for better visibility */
}

.zoom-controls button{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border-color: none;
	border-width: 0;
	background-color: #ababab;
	font-size: 15px;
	padding: 0;
}

.hedder {
    margin-bottom: 1.5rem;
    padding-block: 10px;
    overflow-y: hidden;
    min-height: 100px;
    border-bottom: 1px solid #E1E5F0;
    box-shadow: rgba(100, 100, 111, 0.123) 8px 7px 29px 0px;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.btn {
    padding: 10px 15px;
    color: #fff;
    background-color: #2156a4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

input[type="file"] {
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    color: #333;
	margin-right: 10px;
	text-wrap: wrap;
}


.middle{
	display: flex;
	justify-content: center;
}
h4{
	margin: 0;
}
.labels{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.delete-comment {
    width: 14px;
	cursor: pointer;
	margin-right: 10px
  }
</style>
